import React from 'react';
import ZapretFreonovHtml from './zapretfreonovhtml'


const ZapretFreonov = () => {
    return(
        <ZapretFreonovHtml />
    )
}

export default ZapretFreonov