import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

    //   KLASSY PROTOCOLO
    const klassypop = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">Классы</Popover.Title>
          <Popover.Content>
            <table border="1" style={{maxWidth: '50vw'}}>
                <tr>
                    <th style={{width: '10%', textAlign: 'center'}}>Класс</th>
                    <th style={{width: '10%', textAlign: 'center'}}>Русские <br/>обозначения</th>
                    <th style={{width: '60%', textAlign: 'center'}}>Описание</th>
                    <th style={{width: '20%', textAlign: 'center'}}>Наиболее популярные <br/>фреоны</th>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>Natural <br/>refrigerant</p></td>
                    <td><p>-</p></td>
                    <td><p>Природный хладагент</p></td>
                    <td><p>R717 (аммиак)<br/>R744 (углекислый газ)</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>HFC</p></td>
                    <td><p>ГФУ</p></td>
                    <td><p>Гидрофторуглерод: семейство химических веществ, содержащих водород, фтор и углерод</p></td>
                    <td><p>RR134a<br/>R507<br/>R404a</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>HFO</p></td>
                    <td><p>ГФО</p></td>
                    <td><p>Гидрофторолефин: семейство химических веществ, содержащих водород, фтор и углерод с двойной связью в молекуле</p></td>
                    <td><p>R1234ze<br/>R1234yf</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>HC</p></td>
                    <td><p>-</p></td>
                    <td><p>Углеводороды — органические соединения, состоящие из атомов углерода и водорода.</p></td>
                    <td><p>R290 (пропан)</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>CFC</p></td>
                    <td><p>ХФУ</p></td>
                    <td><p>Хлорфторуглероды — органические соединения, состоящие из атомов хлора (Cl), фтора (F) и углерода (С).</p></td>
                    <td><p>R12<br/>R13</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>CF</p></td>
                    <td><p>ФУ</p></td>
                    <td><p>Фторуглероды (перфторуглеводороды) — углеводороды, в которых все атомы водорода замещены на атомы фтора.</p></td>
                    <td><p>R14</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>HCFC</p></td>
                    <td><p>ГХФУ</p></td>
                    <td><p>Гидрохлорфторуглероды - органические соединения, состоящие из атомов хлора (Cl), фтора (F), водорода (H) и углерода (C).</p></td>
                    <td><p>R22</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>BFC</p></td>
                    <td><p>-</p></td>
                    <td><p>Бромфторуглероды - органические соединения, состоящие из атомов брома (Br), фтора (F), водорода (H) и углерода (C).</p></td>
                    <td><p>R-12B1</p></td>
                </tr>
            </table>
            <p></p>
          </Popover.Content>
        </Popover>
      );

    //   GRUPPY BEZOPASNOSTI PROTOCOLO
    const gruppypop = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">Группа безопасности</Popover.Title>
          <Popover.Content>
            <p>Две группы безопасности хладагентов А и В, определяющие их свойства токсичности. 
            Эти обозначения были введены по стандарту 34-1997 ASHARE «Классификация по группам безопасности».</p>
            <p>Числовое обозначение (1,2 или 3) показывает предрасположенность вещества к воспламеняемости. Также не так давно 
            ASHARE добавил новый подкласс 2. Для хладагентов, которые могут воспламеняться, но процесс горения происходит очень медленно.</p>
            <table border="1" style={{width: '30vw', maxWidth: '50vw'}}>
                <caption>* - A2L, B2L – максимальная скорость горения ≤ 10 см/сек</caption>
                <tr style={{textAlign: 'center'}}>
                    <td><p>Повышенная<br/>огнеопасность</p></td>
                    <td><p>A3</p></td>
                    <td><p>B3</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td rowspan="2"><p>Пониженная <br/>огнеопасность</p></td>
                    <td><p>A2</p></td>
                    <td><p>B2L</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>A2L*</p></td>
                    <td><p>B2L*</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>Нераспространение <br/>пламени</p></td>
                    <td><p>A1</p></td>
                    <td><p>B1</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td style={{borderLeftColor: 'transparent', borderBottomColor: 'transparent'}}></td>
                    <td><p>Пониженная<br/> токсичность</p></td>
                    <td><p>Повышенная<br/> токсичность</p></td>
                </tr>
            </table>
            <p>«А» - вещества с ПДК ≥ 400 мг/м3</p>
            <p>«В» - вещества токсичные при концентрации в воздухе ≤ 400 мг/м3</p>
          </Popover.Content>
        </Popover>
      );

    //   TOCHKA KIPENIYA PROTOCOLO
    const tochkapop = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">Точка кипения </Popover.Title>
          <Popover.Content>
            <p>Точка кипения – температура кипения/конденсации вещества при атмосферном давлении (например, для воды +100С).</p>
          </Popover.Content>
        </Popover>
      );

    //   ODP PROTOCOLO
    const odppop = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">ODP</Popover.Title>
          <Popover.Content>
            <p>Потенциал разрушения озонового слоя (от англ. Ozone Depletion Potential) – коэффициент, обозначающий влияние хладагента 
            на состояние озонового слоя относительно газа R-11 (его величина принята за единицу).</p>
            <p>Самыми первыми запрещенными в широком применении в 1989 году озоноразрущающими фреонами стали R-11 и R-12 (класс CFC), 
            они имеют ODP = 1. По разработанному плану их использование должно быть сокращено и ограничено квотами индивидуальными для 
            каждой страны. Второй класс затронутый Монреальским протоколом был HCFC , наиболее популярный R-22 имеет ODP = 0,055 
            и был запрещен к ввозу в Россию в 2010 году.</p>
          </Popover.Content>
        </Popover>
      );

    //   GWP PROTOCOLO
    const gwppop = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">GWP (ПГП-AR4)</Popover.Title>
          <Popover.Content>
            <p>Потенциал глобального потепления (от англ. Global Warming Potential) – коэффициент, обозначающий с какой степенью, 
            парниковые газы оказывают воздействие на глобальное потепление. </p>
            <p>Обозначение ПГП «-AR4» показывает, что коэффициент рассчитан за столетний промежуток экспертных отчетов (от анлг. assessor report – AR). 
            Базовым парниковым газом был принят углекислый газ СО<sub>2</sub> (GWP = 1). Величины GWP были опубликованы в Кигалийской поправке.</p>
          </Popover.Content>
        </Popover>
      );

    //   MASLO PROTOCOLO
    const maslopop = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">Масло</Popover.Title>
          <Popover.Content>
            <table border="1" style={{width: '30vw', maxWidth: '50vw'}}>
                <tr style={{textAlign: 'center'}}>
                    <td><p>AB</p></td>
                    <td><p>Синтетическое масло</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>MO</p></td>
                    <td><p>Минеральное масло</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>PAG</p></td>
                    <td><p>Холодильное масло PAG изготавливается из полиалкиленгликоля. </p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>POE</p></td>
                    <td><p>Холодильное масло POE (полиэфирное) изготавливается из синтетических эфиров.</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>PAO</p></td>
                    <td><p>Полиальфаолефины PAO - это основа масла (базовое масло), которая используется для производства смазочных материалов.</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><p>MO/AB/PAO</p></td>
                    <td><p>смесь минерального, синтетического и полиальфаолефиного (МО + АВ + PAO)</p></td>
                </tr>
            </table>
            <p></p>
          </Popover.Content>
        </Popover>
      );



    //   PED PROTOCOLO
    const pedpop = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">PED</Popover.Title>
          <Popover.Content>
            <p>Директива 2014/68/EU (PED) о безопасности оборудования, работающего под давлением.</p>
            <p>Группа 1 – рабочие среды, состоящие из взрывчатых, горючих, воспламеняющихся, окисляющихся и токсичных газов, 
            жидкостей и паров в однофазном состоянии, а также их смесей.</p>
            <p>Группа 2 – рабочие среды, которые не относятся к группе 1.</p>
          </Popover.Content>
        </Popover>
      );


const ZapretFreonovTable = () => {

    return(
        <div class="table-wrap">
            <table border="1" style={{width: '100%'}}>
                <tr>
                    <th style={{width: '10%', textAlign: 'center'}}>Вещество</th>
                    <th style={{width: '10%', textAlign: 'center'}}>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={klassypop}>
                            <span style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}> Класс </span>
                        </OverlayTrigger>
                    </th>
                    <th style={{width: '10%', textAlign: 'center'}}>Начало <br/>применения</th>
                    <th style={{width: '14%', textAlign: 'center'}}>Формула</th>
                    <th style={{width: '10%', textAlign: 'center'}}>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={gruppypop}>
                            <span style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}> Группа <br/>безопасности </span>
                        </OverlayTrigger>
                    </th>
                    <th style={{width: '10%', textAlign: 'center'}}>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={pedpop}>
                            <span style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}> Группа жидкости в сотв. PED </span>
                        </OverlayTrigger>
                    </th>
                    <th style={{width: '10%', textAlign: 'center'}}>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={maslopop}>
                            <span style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}>Масло</span>
                        </OverlayTrigger>
                    </th>
                    <th style={{width: '10%', textAlign: 'center'}}>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={tochkapop}>
                            <span style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}>Точка кипения, <br/>при атмосф. давлении</span>
                        </OverlayTrigger>
                    </th>
                    <th style={{width: '6%', textAlign: 'center'}}>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={odppop}>
                            <span style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}>ODP</span>
                        </OverlayTrigger>
                    </th>
                    <th style={{width: '10%', textAlign: 'center'}}>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={gwppop}>
                            <span style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}>GWP-AR4 <br/>(ПГП)</span>
                        </OverlayTrigger>
                    </th>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R717</strong><p>Аммиак (Ammonia)</p></td>
                    <td><p>Natural <br/>refrigerant</p></td>
                    <td><p>1874 год</p></td>
                    <td><p>NH<sub>3</sub></p></td>
                    <td><p>B2L</p></td>
                    <td><p>1</p></td>
                    <td><p>PAG</p></td>
                    <td><p>-33,32</p></td>
                    <td><p>0</p></td>
                    <td><p>0</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R744</strong><p>Углекислота (Carbon dioxide)</p></td>
                    <td><p>Natural <br/>refrigerant</p></td>
                    <td><p>1881 год</p></td>
                    <td><p>CO<sub>2</sub></p></td>
                    <td><p>A1</p></td>
                    <td><p>2</p></td>
                    <td><p>MO/AB/PAO</p></td>
                    <td><p>-62,89</p></td>
                    <td><p>0</p></td>
                    <td><p>10</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R290</strong><p>Пропан (Propanum)</p></td>
                    <td><p>HC</p></td>
                    <td><p>1994 год</p></td>
                    <td><p>CH<sub>3</sub>CH<sub>2</sub>CH<sub>3</sub></p></td>
                    <td><p>A3</p></td>
                    <td><p>1</p></td>
                    <td><p>MO/AB</p></td>
                    <td><p>-42,11</p></td>
                    <td><p>0</p></td>
                    <td><p>3</p></td>
                </tr>
                <tr>
                    <td colspan="10" style={{textAlign: 'center'}}>
                        <p style={{marginTop: '1rem', marginBottom: '0rem'}}>Наиболее популярный фреон в 1950-1980 гг, (в 1987 г. ограничен к применению Монреальским протоколом из-за большого ODP)</p>
                    </td>  
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R12</strong></td>
                    <td><p>CFC</p></td>
                    <td><p>1932 год</p></td>
                    <td><p>CCl<sub>2</sub>F<sub>2</sub></p></td>
                    <td><p>A1</p></td>
                    <td><p>2</p></td>
                    <td><p>MO/AB</p></td>
                    <td><p>-29,75</p></td>
                    <td><p>1</p></td>
                    <td><p>10 900</p></td>
                </tr>
                <tr>
                    <td colspan="10">
                        <p style={{marginTop: '1rem', marginBottom: '0rem'}}>На замену R12 пришёл более новый фреон R-134a:</p>
                    </td>  
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R134a</strong></td>
                    <td><p>HFC</p></td>
                    <td><p>начало 1990-х годов</p></td>
                    <td><p>CH<sub>2</sub>FCF<sub>3</sub></p></td>
                    <td><p>A1</p></td>
                    <td><p>2</p></td>
                    <td><p>POE</p></td>
                    <td><p>-26,07</p></td>
                    <td><p>0</p></td>
                    <td><p>1 430</p></td>
                </tr>
                <tr>
                    <td colspan="10">
                        <p style={{marginTop: '1rem', marginBottom: '0rem'}}>На замену R134a (весьма большой ПГП), появилась более безопасная альтернатива:</p>
                    </td>  
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R1234ze</strong></td>
                    <td><p>HFO</p></td>
                    <td><p></p></td>
                    <td><p>CF<sub>3</sub>CF=CHF</p></td>
                    <td><p>A2L</p></td>
                    <td><p>2</p></td>
                    <td><p>POE</p></td>
                    <td><p>-18,97</p></td>
                    <td><p>0</p></td>
                    <td><p>7</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R1234yf</strong></td>
                    <td><p>HFO</p></td>
                    <td><p>2014 год</p></td>
                    <td><p>CF<sub>3</sub>CF=CH<sub>2</sub></p></td>
                    <td><p>A2L</p></td>
                    <td><p>1</p></td>
                    <td><p>POE</p></td>
                    <td><p>-29,49</p></td>
                    <td><p>0</p></td>
                    <td><p>4</p></td>
                </tr>
                <tr>
                    <td colspan="10">
                        <p style={{marginTop: '1rem', marginBottom: '0rem'}}>Наиболее популярный фреон во второй половине XX века, запрещен Монреальским протоколом из-за большого ODP 
                            (Лондонская поправка июнь 1990г):</p>
                    </td>  
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R22</strong></td>
                    <td><p>HCFC</p></td>
                    <td><p>1935 год</p></td>
                    <td><p>CHClF<sub>2</sub></p></td>
                    <td><p>A1</p></td>
                    <td><p>2</p></td>
                    <td><p>MO/AB</p></td>
                    <td><p>-40,81</p></td>
                    <td><p>0,055</p></td>
                    <td><p>1 810</p></td>
                </tr>
                <tr>
                    <td colspan="10">
                        <p style={{marginTop: '1rem', marginBottom: '0rem'}}>На смену R22 в конце XX века нашли применение фреоны, которые не имеют воздействие на озоновый слой (ODP=0). 
                            Они широко распространены и применяются по сей день:</p>
                    </td>  
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R407C</strong></td>
                    <td><p>HFC</p></td>
                    <td><p>конец 1980-х годов</p></td>
                    <td><p>R-32/125/134a<br/>(23/25/52)</p></td>
                    <td><p>A1</p></td>
                    <td><p>2</p></td>
                    <td><p>POE</p></td>
                    <td><p>-43,63</p></td>
                    <td><p>0</p></td>
                    <td><p>1 774</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R410A</strong></td>
                    <td><p>HFC</p></td>
                    <td><p>начало 1990-х годов</p></td>
                    <td><p>R-32/125<br/>(50/50)</p></td>
                    <td><p>A1</p></td>
                    <td><p>2</p></td>
                    <td><p>POE</p></td>
                    <td><p>-51,44</p></td>
                    <td><p>0</p></td>
                    <td><p>2 088</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R404A</strong></td>
                    <td><p>HFC</p></td>
                    <td><p>1993 год</p></td>
                    <td><p>R-125/143a/134a<br/>(44/52/4)</p></td>
                    <td><p>A1</p></td>
                    <td><p>2</p></td>
                    <td><p>POE</p></td>
                    <td><p>-46,22</p></td>
                    <td><p>0</p></td>
                    <td><p>3 922</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R507</strong></td>
                    <td><p>HFC</p></td>
                    <td><p>1990-е года</p></td>
                    <td><p>R-125/143a <br/>(50/50)</p></td>
                    <td><p>A1</p></td>
                    <td><p>2</p></td>
                    <td><p>POE</p></td>
                    <td><p>-46,74</p></td>
                    <td><p>0</p></td>
                    <td><p>3 985</p></td>
                </tr>
                <tr>
                    <td colspan="10">
                        <p style={{marginTop: '1rem', marginBottom: '0rem'}}>На замену R404a, R507a (появилась более безопасная альтернатива):</p>
                    </td>  
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R448A</strong></td>
                    <td><p>HFC/HFO</p></td>
                    <td><p>начало 2010-х годов</p></td>
                    <td><p>R-32/125/134a/1234ze<br/>(26/26/21/7/20)</p></td>
                    <td><p>A1</p></td>
                    <td><p>2</p></td>
                    <td><p>POE</p></td>
                    <td><p>-46,12</p></td>
                    <td><p>0</p></td>
                    <td><p>1 387</p></td>
                </tr>
                <tr style={{textAlign: 'center'}}>
                    <td><strong>R449A</strong></td>
                    <td><p>HFC/HFO</p></td>
                    <td><p>начало 2010-х годов</p></td>
                    <td><p>R-32/125/1234yf/134a <br/>(24,3/24,7/25,3/25,7)</p></td>
                    <td><p>A1</p></td>
                    <td><p>2</p></td>
                    <td><p>POE</p></td>
                    <td><p>-45,72</p></td>
                    <td><p>0</p></td>
                    <td><p>1 397</p></td>
                </tr>
            </table>
        </div>
    )
}

export default ZapretFreonovTable