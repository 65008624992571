import React from 'react';
import { Container, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative'
import Footer from '../../Modules/FooterPage'

import Table from './zapret-tablitza'


const monrealpop = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Монреальский протокол</Popover.Title>
      <Popover.Content>
        <p><strong>Принят 16 сентября 1987 года в городе Монреале (Канада).</strong></p>
        <p class="page-img">
            <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/predstaviteli-stran-podpisavshih-monrealskij-protokol.jpg" alt="Озоновая дыра планеты Земля" />
        </p>
        <p>Международный протокол (к Венской конвенции об охране озонового слоя 1985 года) по веществам, разрушающим озоновый слой. 
        Целью его создания является защита озонового слоя, с помощью постепенного отказа от озоноразрушающих веществ и снятия их 
        с производства.</p>
        <em>Основные запрещенные вещества:</em>
        <table border="1">
            <caption>* - нет точных данных</caption>
            <tr>
                <th>Химическое вещество</th>
                <th>ODP</th>
            </tr>
            <tr><td>R11</td><td>1,0</td></tr>
            <tr><td>R12</td><td>1,0</td></tr>
            <tr><td>R113</td><td>0,8</td></tr>
            <tr><td>R114</td><td>1,0</td></tr>
            <tr><td>R115</td><td>0,6</td></tr>
            <tr><td></td><td></td></tr>
            <tr><td>R12B1</td><td>3,0</td></tr>
            <tr><td>R13B1</td><td>10,0</td></tr>
            <tr><td>R114B2</td><td>-*</td></tr>
        </table>
        <p>Монреальский протокол вступил в силу 1 января 1989 года. К настоящему времени обязанности по его выполнению взяли на себя 
        197 стран из 202. Импорт и экспорт новых, использованных, переработанных или утилизированных веществ должен 
        осуществляться по внедренной в каждой стране лицензированной системе.</p>
        <p>Большинство стран создали необходимые системы, но не во всех странах они внедрены безукоризненно.
        Некоторые страны не охватывают все регулируемые вещества или смеси, их содержащие из-за специфичных 
        местных  условий системы. Есть страны, которые позволяют контрабандистам удачно использовать их в 
        качестве промежуточных остановок. Это связано с тем, что в таких странах система лицензирования применима 
        только для импорта озоноразрушающих веществ, несмотря на требования Протокола.</p>
        <p>Квоты на потребление или запреты также  могут накладывать ограничения на импортные поставки.
        Годовые квоты определяются странами по каждому озоноразрушающему веществу и с каждым годом они должны постепенно уменьшаться. 
        Запреты накладываются на конкретные вещества, и соответственно полностью прекращается импорт.</p>
        <p>Импорт или экспорт при трансграничных перевозках не засчитываются, как потребление озоноразрушающих веществ. Такие перевозки требуют 
        тщательного мониторинга так, как могут быть перенаправлены по другому маршруту и оказаться на «черном рынке».</p>
        <p>Контроль торговли озоноразрушающих веществ осуществляется Монреальским протоколом, а Киотский протокол в свою очередь не содержит никаких пунктов о торговле.</p>
      </Popover.Content>
    </Popover>
  );

//   KIOTO PROTOCOLO
const kiotopop = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Киотский протокол</Popover.Title>
      <Popover.Content>
        <p><strong>Принят 11 декабря 1997 года в городе Киото (Япония).</strong></p>
        <p class="page-img">
            <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/kiotskiy-protokol.jpg" alt="Озоновая дыра планеты Земля" />
        </p>
        <p>Международный протокол - целью которого является противодействие глобальному потеплению с помощью сокращения выбросов парниковых газов в атмосферу.</p>
        <p>Киотский протокол вступил в силу 16 февраля 2005 года и к настоящему времени обязательства по его выполнению взяли на себя 192 стран из 202. Углекислый газ (CO2) , метан (CH4), оксид диазота (N2O), класс гидрофторуглероды (HFC), 
        класс перфторуглероды (CF) и гексафторид серы (SF6) – вещества, к которым применим протокол.</p>
        <p>Отношение России к Киотскому протоколу:</p>
        <p>На Всемирной конференции по изменению климата в Москве в сентябре 2003 года обсуждение переросло в интенсивные дебаты внутри страны. На них рассматривали все аргументы за и против ратификации (утверждения) Киотского протокола.</p>
        <table border="1" style={{maxWidth: '40vw'}}>
            <tr>
                <th style={{width: '50%', textAlign: 'center'}}>«За» ратификацию</th>
                <th style={{width: '50%', textAlign: 'center'}}>«Против» ратификации</th>
            </tr>
            <tr>
                <td>
                    <ul>
                        <li>Губительные последствия изменения климата</li>
                        <li>Поддержка энергосбережения и инноваций</li>
                        <li>Упрощение процесса вступления во Всемирную торговую организацию</li>
                        <li>Повышение репутации России на мировой арене</li>
                        <li>Доход от торговли квотами</li>
                        <li>Привлечение иностранных инвестиций</li>
                    </ul>
                </td>
                <td>
                    <ul>
                        <li>Не все страны Киотского протокола взяли на себя обязательства по снижению выбросов</li>
                        <li>После 2012 г. взятые обязательства по дополнительному сокращению выбросов парниковых газов ухудшит экономический рост</li>
                        <li>Ощутимые затраты на выполнение обязательств</li>
                        <li>Предположение, что потепление будет плюсом для холодного климата России</li>
                    </ul>
                </td>
            </tr>
        </table>
        <p>Решение о ратификации протокола, было скорее продиктовано политическими соображениями. Принятые решения правительством РФ привели к тактическому успеху. 
        Россия остаётся основным действующим лицом на климатических переговорах.</p>
        <p>Общий объем выбросов в атмосферу углекислого газа, метана и различных промышленных газов в России в период с 2008 года по 2012 года должен быть сокращен 
        на 5,2% относительно уровня 1990 года.</p>

        <ul>
            <li><strong>0,43 градуса по Цельсию</strong> – настолько с 1970-х годов за каждые 10 лет в России вырастает температура воздуха из-за глобального потепления.</li>
            <li><strong>17 %</strong> - такова доля России в мире, выбросов парниковых газов в атмосферу.</li>
            <li><strong>2 млрд. 321 млн. тонн в пересчёте на СО2</strong>  – количество выбросов парниковых газов в России на момент 2011 года. Этот объем меньше на 30,8% относительно 1990 года.</li>
            <li><strong>От 30 до 60 млрд. рублей</strong> – ежегодный ущерб России, наносимый природно-климатическими явлениями, вызванными экологическими проблемами (в том числе глобальным потеплением) на нашей планете.</li>
        </ul>

        <p>В декабре 2015 года, выступая на 70-й сессии Генеральной ассамблеи ООН Владимир Путин отметил, что 
        Россия планирует ограничить выбросы парниковых газов до 70-75% к 2030 г. относительно 1990 г., 
        в рамках мирового вклада в борьбу против глобального потепления.</p>
      </Popover.Content>
    </Popover>
  );

  //   KIGALIY PROTOCOLO
const kigalypop = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Кигалийская поправка</Popover.Title>
      <Popover.Content>
        <p><strong>Принята 15 октября 2016 года в городе Кигали (Руанда).</strong></p>
        <p class="page-img">
            <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/Kigali.jpg" alt="Озоновая дыра планеты Земля" />
        </p>
        <p>Класс HFC успешно заменил озоноразрушающие вещества, имея ODP = 0. Однако этот класс является весьма активными 
        парниковыми газами, и имеют очень высокий GWP равный от 121 до 14 800.</p>
        <p>Кигалийская поправка вступила в силу 1 января 2019 года, однако в России это произошло только 1 января 2021 года.</p>
        <p>Для России по данной поправке предусмотрен указанный план сокращения производства и потребления класса гидрофторуглеродов (HFC):</p>
        
        <table border="1" style={{maxWidth: '40vw'}}>
            <tr>
                <th colspan="1" style={{width: '33%', textAlign: 'center'}}>Базовые годы</th>
                <th colspan="2" style={{width: '67%', textAlign: 'center'}}>2011, 2012 и 2013</th>
            </tr>
            <tr>
                <td colspan="1" style={{textAlign: 'center'}}>
                    <p>Расчет Базового уровня потребления</p>
                </td>
                <td  colspan="2" style={{textAlign: 'center'}}>
                    <p>Средний уровень производства/потребления HFC в 2011, 2012 и 2013 годах. 
                    Плюс 25% базового уровня производства/потребления HCFC.</p>
                </td>
            </tr>
            <tr>
                <td colspan="3" style={{textAlign: 'center'}}>
                    <p>Этапы сокращения потребления к Базовому уровню</p>
                </td>  
            </tr>
            <tr>
                <td style={{width: '33%', textAlign: 'center'}}>Этап 1</td>
                <td style={{width: '33%', textAlign: 'center'}}>2019 – 2020</td>
                <td style={{width: '33%', textAlign: 'center'}}>5%</td>
            </tr>
            <tr>
                <td style={{width: '33%', textAlign: 'center'}}>Этап 2</td>
                <td style={{width: '33%', textAlign: 'center'}}>2021 – 2025</td>
                <td style={{width: '33%', textAlign: 'center'}}>35%</td>
            </tr>
            <tr>
                <td style={{width: '33%', textAlign: 'center'}}>Этап 3</td>
                <td style={{width: '33%', textAlign: 'center'}}>2026 – 2029</td>
                <td style={{width: '33%', textAlign: 'center'}}>70%</td>
            </tr>
            <tr>
                <td style={{width: '33%', textAlign: 'center'}}>Этап 4</td>
                <td style={{width: '33%', textAlign: 'center'}}>2030 – 2034</td>
                <td style={{width: '33%', textAlign: 'center'}}>80%</td>
            </tr>
            <tr>
                <td style={{width: '33%', textAlign: 'center'}}>Этап 5</td>
                <td style={{width: '33%', textAlign: 'center'}}>2035 – 2036</td>
                <td style={{width: '33%', textAlign: 'center'}}>85%</td>
            </tr>
        </table>
        <p></p>
      </Popover.Content>
    </Popover>
  );




const ZapretFreonovPage = () => {

    return(
        <>
        <Container fluid>
            <Row>
                <Col>
                    <TopDark />
                </Col>
            </Row>
        </Container>
                <div className="refeng-page-container">
                    <div className="postcontent">
                        <h1>Запрет фреонов</h1>
                        <p>Самые различные виды холодильного оборудования давно стали чем-то настолько привычным для нас, что мы не представляем свою жизнь без них. Домашний холодильник, кондиционеры, промышленные холодильные установки, камеры шоковой заморозки, холодильные витрины и т.д. Прогресс не стоит на месте и на перечисление всего известного холодильного оборудования понадобится немало времени. Мы все знаем, что есть один компонент, благодаря которому все эти системы могут вырабатывать холод для нас.</p>
                        <p>Холодильные агенты (хладагенты) - вещества, используемые в холодильных установках, способствующие охлаждению среды. Процесс охлаждения происходит за счет смены агрегатного состояния. Во время этого процесса хладагент забирает тепло у охлаждаемого объекта, расходуя его на процесс фазового перехода хладагента из жидкого в газообразное состояние.  Если совсем просто на примере домашнего холодильника, то хладагент забирает тепло из воздуха камеры холодильника, благодаря чему воздух и охлаждается.</p>
                        <p>Формально, можно сказать, что любое вещество является хладагентом. Например, вода, которая при атмосферном давлении кипит при температуре +100°С и забирает тепло у источника тепла или, другими словами, охлаждает его.</p>
                        <p>Наиболее распространёнными хладагентами являются фреоны, углекислота, и аммиак. Фреоны - название группы фторсодержащих производных насыщенных углеводородов. Они находят широкое применение в маленьких и средних по мощности холодильных установках. Большинство из них производится химической промышленностью, на данный момент известно более 40 различных однокомпонентных фреонов и сотни смесевых фреонов.</p>
                            <p class="page-img">
                                <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/vidy-freonov.jpg" alt="Виды фреонов" />
                            </p>
                        <h2>Историческая справка</h2>
                        <p>Конец 19 и начало 20 века - время рассвета холодильной промышленности.</p>
                        <p>Используемые в те времена вещества были не просто опасны, но и губительны для человеческой жизни.</p>
              
                        <br/>
                        <h3>Разрушение озонового слоя</h3>
                        <p>В 80-х годах случился переломный момент для фреонов и всей холодильной промышленности. Ученые стали активно изучать причины разрушения озонового слоя и пришли к выводу, что фреоны наносят ощутимый ущерб.</p>
                            <p class="page-img">
                                <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/ozon_hole.jpg" alt="Озоновая дыра планеты Земля" />
                            </p>
                        <p>Большинство стран мира объединились, чтобы решить сложившуюся проблему. Было принято несколько протоколов и проведено множество встреч по обсуждению выходов из этой ситуации. </p>
                        <p>Проблема экологии смогла перевернуть холодильную промышленность и объединить невероятное количество стран мира 197 из 202. Самая первая конференция была в 1985 году в Вене, но первые юридические обязательные цели был изложены в                     
                            <OverlayTrigger trigger="click" placement="bottom" overlay={monrealpop}>
                                <em style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}> Монреальском протоколе 1987 года (Канада, Монреаль). </em>
                            </OverlayTrigger>
                        </p>
                            
                            <p class="page-img">
                                <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/predstaviteli-stran-podpisavshih-monrealskij-protokol.jpg" alt="Представители стран, подписавших Монреальский договор" />
                            </p>
                        <p>Фреоны были очень используемы по всему миру, особенно, класс хлорфторуглеродов (CFC, наиболее популярный фреон R-12), которые собирались запретить Монреальским протоколом. Сложилась очень тяжелая ситуация, как прийти к задуманному с минимальными потерями, особенно всех интересовала экономическая сторона этого вопроса.</p>
                            <p class="page-img">
                                <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/hfu.jpg" alt="График выбросов, разрушающих озоновый слой" />
                            </p>
                        <p>Каждая страна стала разрабатывать свои планы действий - лицензирование экспорта и импорта озоноразрушающих веществ.</p>
                        <br/>
                        <h3>Проблема изменения климата</h3>
                        <p>Не менее важным стал 
                            
                        <OverlayTrigger trigger="click" placement="bottom" overlay={kiotopop}>
                            <em style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}> Киотский протокол 1997 года, </em>
                        </OverlayTrigger>
                            
                            он направлен на сокращение выбросов парниковых газов в атмосферу.</p>
                            <p class="page-img">
                                <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/kiotskiy-protokol.jpg" alt="Заседание слушаний по Киотскому протоколу" />
                            </p>
                        <p>Регулирование потребления/производства этих газов планировалось вести по рассчитанным квотам для каждой страны. В случае имеющихся свободных квот их можно было продать другой стране. Россия имела одну из самых крупных долей выбросов парниковых газов. Несмотря на то, что это плохой показатель для экологии, именно этот фактор и верно выбранная тактика, способствовали тому, что Россия останется основным действующим лицом на климатических переговорах.</p>
                            <p class="page-img">
                                <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/parnikovye-gazy.jpg" alt="График совокупных парниковых выбросов России" />
                            </p>
                        <p>В дальнейшем к протоколу были приведены поправки, дополняющие его содержание: Лондонская, Копенгагенская, Монреальская, Пекинская и Кигалийская.</p>
                        <p>Стоит обратить внимание 
                            
                            <OverlayTrigger trigger="click" placement="bottom" overlay={kigalypop}>
                                <em style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}> на Кигалийскую поправку, </em>
                            </OverlayTrigger>
                            
                            принятую совсем недавно в 2019 году.</p>
                            <p class="page-img">
                                <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/Kigali.jpg" alt="Кигалийская конференция" />
                            </p>
                        <p>Она направлена на приостановление глобального потепления, с помощью постепенного сокращения производства и потребления класса HFC (гидрофторуглероды). Этот класс стал в свое время успешной заменой озоноразрушающим веществам, но решив одну проблему столкнулись с другой. Газы из этого класса имеют высокие показатели потенциала глобального потепления, тем самым оказывая большое влияние на увеличение температуры окружающей среды.</p>
                        <p>В феврале 2022г. в России вводится механизм государственного регулирования объёмов ввоза, производства и использования в промышленности гидрофторуглеродов, которые относятся к группе парниковых газов и негативно влияют на климат планеты.</p>
                        <p>В рамках выполнения обязательств России по Монреальскому протоколу по веществам, разрушающим озоновый слой, был принят документ, которые определил перечень гидрофторуглеродов, попадающих под государственный контроль. В их числе – дифторметан, декафторпентан, трифторметан, фторметан, гексафторпропан.
                        Годовой объем их ввоза, производства и использования в промышленности будет постепенно снижаться. Так, с 2022 года он не должен превышать 46,2 млн т, с 2024 года – 31,6 млн т, с 2029 года – 14,6 млн т, с 2034 года – 9,7 млн т. С 2036 года и далее этот показатель не должен превышать 7,3 млн т. Таким образом, потребление гидрофторуглеродов за эти годы сократится на 85%.
                        </p>
                        <p>Гидрофторуглероды широко используются в промышленности, например в производстве холодильников, кондиционеров, аэрозолей, ингаляторов. Они также наносят ущерб климату планеты, создают парниковый эффект и способствуют глобальному потеплению. Поэтому необходимо регулировать объемы импорта, производства и использования данных веществ.</p>
                        <br/>
                        <h3>Характеристики холодильных агентов</h3>
                        <p>Рассмотрим характеристики самых популярных фреонов. Более ранние и пришедшие на их замену более безопасные.</p>
                            
                            <Table />

                            {/* <p class="page-img">
                                <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/freontbl.svg" alt="Таблица фреонов с характеристиками" />
                            </p> */}

                        <p>Новые альтернативы R1234ze и R1234yf используются для кондиционирования. Они являются экологически безопасными (наносят минимальный ущерб окружающей среде), но горючие и легко воспламеняемы в некоторых условиях.</p>
                        <p>В холодильной промышленности (холодильные камеры в широком диапазоне) достойной альтернативы популярным хладагентам R404А R507 до сих пор нет. Фреоны R448А и R449А можно считать промежуточными - они являются негорючими, но имеют большой потенциал глобального потепления (GWP).</p>
                            <p class="page-img">
                                <img class="content-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/zapret_freonov/freon-types.jpg" alt="Типы фреонов" />
                            </p>
                        <br/>
                        <p>Подведем небольшой итог. Недостаток безопасных для экологии хладагентов и вводимые ограничения  приводят к неизбежному росту цен.</p>
                        <p>В начале 2021 года наиболее популярные фреоны (R134a, R404А, R507, R410А) стоили ориентировочно 3500 – 4500 рублей за баллон (~11-14 кг). В то время, как в Европе цена на эти фреоны составляет 500 – 1000 евро (~45 500-91 000 рублей), а их потребление строго регулируется местным законодательством.</p>
                        <p>Рост европейских цен на фреоны с высоким GWP не останавливается и уже сейчас в Швейцарии цена на фреон R404А для конечных потребителей составляет 300евро за 1кг.</p>
                        <p>В конце марта 2021 г. цена на популярные фреоны в России резко возросла в моменте до 12 000 рублей за баллон, но в дальнейшем скорректировалась до уровней 8 000-9 0000 рублей.</p>
                        <p>Причиной резкого роста цен послужил установленный с 18 апреля 2021 г. разрешительный порядок ввоза хладагентов группы ГФУ на территорию Евразийского Экономического Союза. После установления разрешительного порядка организация импортер может ввезти фреон только при наличии лицензии Минпромторга, которая оформляется на основании разрешительного документа (заключения) выдаваемого Росприроднадзором.</p>
                        <p>При этом внутренние цены в России все равно остаются принципиально ниже Европейских. В 2021 г. квоты на количество ввозимых ГФУ (HFC) не установлены по причине профицитного объема потребления ГФУ и отсутствия распределения квот. Однако в будущем планируется введение квот исходя из пересчета массы ГФУ на потенциал глобального потепления (перевод в тонны CO2) согласно приложения Е Кигалийской поправки к Монреальскому протоколу. 
                        Таким образом, в долгосрочной перспективе следует ожидать рост цен до уровня Европейских.</p>
                        <p>Аналогичный вектор на хладагенты с низким ПГП (GWP) демонстрируют вступившие в силу с 1 июля 2021 г. СП 60.13330.2020 “Отопление, вентиляция и  кондиционирование воздуха”, которые теперь содержат следующие требования: 
                        В системах холодоснабжения следует использовать холодильные машины и установки, работающие на экологически безопасных хладагентах с нулевой озоноразрушающей способностью и потенциалом глобального потепления не выше 2 500 (ГОСТ EN 378-1–2014, приложения В, Е). Таким образом, если “кондиционерные” R134a и  R410a еще удовлетворяют данному требованию, то повсеместные “холодильные” R507a и R404a уже не удовлетворяют, обладая коэффициентом ПГП 3780 и 3850.</p>
                        <p>Ситуация осложнена тем, что на данный момент в мире нет хладагентов, которые не оказывали бы влияние на глобальное потепление и при этом были бы негорючими и не взрывоопасными. В ближайшее время очень маловероятно их появление.</p>
                        <p>Есть три основных хладагента, которые не имеют перспектив на экологический запрет, но имеют свои недостатки. Остановимся на каждом более подробно.</p>
                            <ul>
                                <li>CO<sub>2</sub> (R-744, углекислый газ или углекислота или двуокись углерода)<p>Хорошие показатели для экологии и не токсичный, но имеет высокие давления в системе и не имеет выдающих показателей энергоэффективности.</p>
                                <p>В режиме низких и сверхнизких температур при прочих равных показатели энергоэффективности CO2 соизмеримы с показателями наиболее популярных фреонов, но для режима средних температур и кондиционирования проигрыш CO2 достаточно существенный.
                                Углекислота может быть использована в нижнем каскаде субкритического цикла (в верхнем обычно фреон/аммиак), и как самостоятельный хладагент в транскритическом цикле.
                                </p>
                                <p>Некоторые особенности теплофизических свойств углекислоты (высокие температуры нагнетания и низкая критическая точка +30,98°С) позволяют конфигурировать более сложные холодильные системы с параллельным сжатием в верхней ступени, десуперхитерами, инжекторами и другими модернизациями, которые в конечном итоге позволяют получить суммарный эффект в энергоэффективности лучше чем в традиционных фреоновых системах. 
                                Но если сравнивать бустерный (двухступенчатый) цикл сжатия для фреоновой системы и для CO2 без дополнительных модернизаций, то энергоэффективность будет соизмерима, вопреки устоявшемуся мнению, что системы на CO2 более энергоэффективны чем фреоновые из-за физических свойств самой углекислоты.</p>
                                <p>В Европе CO2 является хладагентом первого выбора для систем малой и средней производительности. В последние годы активно внедряется в России, в том числе силами компании Рефинжиниринг.</p>
                                <p><a href="/realizovannye_proekty/bimbo">Смотреть выполненный проект роботизированного фризера Bimbo на CO2</a></p>
                                <p style={{color: "#00a0e3"}}>В процессе реализации проект МПК Обнинский на CO2</p>
                                </li>
                                
                                <li>NH<sub>3</sub> (R-717, аммиак) <p>Лучший по энергоэффективности и экологичности, токсичный и взрывоопасный при определенных концентрациях.</p>
                                <p>“Классический” хладагент для больших промышленных систем и крупных предприятий. Для аммиачных систем действуют ФНП “Правила безопасности аммиачных холодильных установок”, требуются расширенные допуски на проектирование и выполнение работ на особо опасных обьектах, экспертиза промышленной безопасности, постановка на учет в Ростехнадзоре. Тем не менее, остается хладагентом первого выбора для систем большой производительности.</p>
                                <p>На предприятиях России с аммиачными системами востребованы решения по замене изношенного оборудования, с одновременным многократным  снижением емкости заправки аммиака. Один из подходов - после реконструкции производственные помещения переводятся на хладоноситель, а аммиак остается в коротком контуре, не выходящем за пределы помещения АХУ. </p>
                                <p><a href="/realizovannye_proekty/bikom-cherkizovo/">Смотреть выполненный проект для ГК Черкизово на NH3</a></p>
                                </li>
                                
                                <li>C<sub>3</sub>H<sub>8</sub> (R-290, пропан) <p>Средний по энергоэффективности, экологичный, но высоко горючий и взрывоопасный (группа А3).</p>
                                <p>В России пропан распространен мало (единичные проекты небольшой производительности). В Европе довольно популярен ввиду экологичности (за последние несколько лет наблюдается большой рост по пропановым холодильным машинам, последние три года объём рынка в Европе удваивается каждый год). </p>
                                <p>Используется в основном в коротких контурах в чиллерах небольшой и средней производительности (до 500кВт). Ввиду своих горючих и взрывоопасных свойств требует обязательной оценки рисков на предмет образования взрывоопасной среды и возможных требований к взрывозащищенному исполнению оборудования. </p>
                                <p>Есть две особенности применения в системах холодоснабжения:</p>
                                <ol>
                                    <li>Очень большая растворимость в холодильном масле.</li>
                                    <li>В жидком состоянии имеет очень маленькую плотность (при температуре +35°С 292,8кг/м³).</li>
                                </ol>
                                </li>
                            </ul>

                        <p class="page-header">
                            <strong>
                                    Мы проектируем наши системы холодоснабжения и подбираем хладагент исходя из требуемых задач, учитывая вышеописанные 
                                    факторы и детали проекта.
                            </strong>
                        </p>
                    </div>
                </div>
            <Footer />
        </>
    )
}

export default ZapretFreonovPage