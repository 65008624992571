import React from "react"
import Seo from "../../components/seo"

import ZapretFreonovComponent from "../../components/BlogPages/ZapretFreonov"


const ZapretFreonov = () => (

  <>
    <Seo 
      title="Запрет некоторых видов фреонов. Характеристики наиболее востребованных видов хладагентов."
      description="Запрет некоторых видов фреонов направлен на улучшение экологической обстановки на планете, снижение разрушающего воздействия на озоновый слой Земли. Характеристики наиболее востребованных видов хладагентов. Достоинства и недостатки фреонов, не имеющих перспектив на запрет."
      keywords="фреоны, запрет, хладагенты, характеристики, безопасность"
    />
    <ZapretFreonovComponent />
  </>
  
)

export default ZapretFreonov